import classNames from 'classnames'
import Image from 'next/image'
import {MediaItem} from '../../types/paragraphs'
import ConditionalWrapper from './ConditionalWrapper'

type Props = {
  item: MediaItem
  wrapperClass?: string
  imageClass?: string
  layout?: 'intrinsic' | 'fixed' | 'responsive' | 'fill'
  objectFit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down'
  priority?: boolean
  width?: number
  height?: number
  sizes?: string
}

const MediaRenderer = ({
  item,
  layout = 'intrinsic',
  wrapperClass = '',
  imageClass = '',
  objectFit,
  priority = false,
  width,
  height,
  sizes,
}: Props) => {
  if (!item || (item.type !== 'media--video' && !(item as any))) {
    return null
  }
  const isVideo = item.type === 'media--video'
  const documentFile = item.type === 'media--document'

  const {NEXT_PUBLIC_API_URL} = process.env

  const generateListOfFiles = () => {
    if (!item.field_media_file) {
      return
    }
    return (
      <li className="py-1">
        <a
          href={`${NEXT_PUBLIC_API_URL}${item.field_media_file?.uri?.url}`}
          target="_blank"
          rel="noreferrer"
          className="list break-word file-name"
        >
          {item.name}
        </a>
      </li>
    )
  }

  return (
    <ConditionalWrapper
      condition={!!wrapperClass}
      wrapper={(childrenPassed: any) => (
        <div className={`${wrapperClass} d-flex align-items-center`}>
          {childrenPassed}
        </div>
      )}
    >
      {isVideo && item.field_media_oembed_video ? (
        <div className="video-wrapper">
          <div className="video-responsive">
            <iframe
              src={`${item.field_media_oembed_video
                .replace('youtube.com', 'youtube-nocookie.com')
                .replace('watch?v=', 'embed/')}?rel=0&enablejsapi=1`}
              title={item.name}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;"
              allowFullScreen={true}
            />
          </div>
        </div>
      ) : null}
      {
        <>
          {item.field_media_image?.uri?.url &&
          item.thumbnail.resourceIdObjMeta ? (
            <Image
              src={
                item.field_media_image.uri?.url.indexOf(
                  process.env.NEXT_PUBLIC_API_URL!,
                ) < 0
                  ? `${process.env.NEXT_PUBLIC_API_URL}${item.field_media_image.uri.url}`
                  : item.field_media_image.uri.url
              }
              sizes={sizes}
              width={
                layout === 'fill'
                  ? undefined
                  : width ?? item.thumbnail.resourceIdObjMeta.width!
              }
              height={
                layout === 'fill'
                  ? undefined
                  : height ?? item.thumbnail.resourceIdObjMeta.height
              }
              fill={layout === 'fill'}
              className={classNames(
                imageClass,
                objectFit ? `object-fit-${objectFit}` : '',
              )}
              priority={priority}
              alt={item.thumbnail.resourceIdObjMeta.alt ?? ''}
            />
          ) : null}
        </>
      }
      {documentFile ? generateListOfFiles() : null}
    </ConditionalWrapper>
  )
}

export default MediaRenderer
