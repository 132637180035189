import { useTranslations } from 'next-intl';
import Image from 'next/image'
import { Combobox } from '@headlessui/react'
import striptags from 'striptags'
import magnifier from '../../../public/magnifier-icon.svg'
import { useState } from 'react'
import classNames from 'classnames'

type SearchBoxProps = {
  handleSearchBoxInput: (arg1: string) => void
  searchInput: string
  suggestions?: string[] | null
  extraClass?: string
  placeholder?: string
  showLeftSearchIcon?: boolean
  submitHandler: (e: string) => void
}

const SearchBox = ({
  handleSearchBoxInput,
  searchInput,
  suggestions,
  extraClass,
  placeholder,
  submitHandler,
  showLeftSearchIcon = false,
}: SearchBoxProps) => {
  const [searchQuery, setSearchQuery] = useState('')

  const t = useTranslations()
  const optionClassNames = (active: boolean, selected: boolean) =>
    classNames('d-block p-2', {
      'bg-slate-grey': active,
      'bg-orange text-white': selected,
    })

  return (
    <label
      htmlFor="search"
      id="searchLabel"
      className={`position-relative c-search-input d-block w-100 ${extraClass ? extraClass : ''
        } ${showLeftSearchIcon ? 'c-search-input__extra-padding' : ''}`}
    >
      {showLeftSearchIcon ? (
        <span className="position-absolute ml-4 mt-4 bg-">
          <Image src={magnifier} alt="magnifier"></Image>
        </span>
      ) : null}
      <Combobox
        value={searchInput}
        onChange={e => {
          submitHandler(e)
          setSearchQuery(e)
          handleSearchBoxInput(e)
        }}
      >
        <Combobox.Input
          className="w-100"
          id="search"
          aria-labelledby="searchLabel"
          placeholder={
            placeholder ?? striptags(t.raw('jobs_search_box_placeholder'))
          }
          onChange={event => {
            setSearchQuery(event.target.value)
            handleSearchBoxInput(event.target.value)
          }}
        />
        <Combobox.Options
          className={`c-search-input__suggestions ${!suggestions || suggestions.length === 0 ? 'pb-0' : ''
            }`}
        >
          {searchQuery.length > 0 && (
            <Combobox.Option
              value={searchQuery}
              className="d-none"
            ></Combobox.Option>
          )}
          {suggestions?.slice(0, 3).map(suggestion => (
            <Combobox.Option key={suggestion} value={suggestion}>
              {({ active, selected }) => (
                <div className={optionClassNames(active, selected)}>
                  <span className="pl-2">{suggestion}</span>
                </div>
              )}
            </Combobox.Option>
          ))}
        </Combobox.Options>
      </Combobox>
    </label>
  )
}
export default SearchBox
