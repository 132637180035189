import {Disclosure, Tab} from '@headlessui/react'
import classNames from 'classnames'
import {useRouter} from 'next/router'
import React from 'react'
import {AdditionalContent} from '../../types/api-types'
import {JobSearchLink, JobSearchLinkTabQuickLink} from '../../types/paragraphs'
import GenericLink from '../shared/Link'
import MediaRenderer from '../shared/MediaRenderer'
import SearchBox from './JobOverview/SearchBox'
import {trpc} from '../../utils/trpc'

type JobSearchLinkProps = {
  paragraph: JobSearchLink
  additionalContent: AdditionalContent
}

const JobSearchLink = ({paragraph, additionalContent}: JobSearchLinkProps) => {
  const router = useRouter()
  const [searchInput, setSearchInput] = React.useState('')

  const {data: suggestionsData} = trpc.getSuggestions.useQuery(
    {locale: router.locale ?? 'nl-be', search: searchInput},
    {
      refetchOnWindowFocus: false,
      enabled: !!searchInput && searchInput.length > 1,
    },
  )
  const onSearchSubmit = (e?: string | React.MouseEvent<HTMLElement>) => {
    if (typeof e === 'string') {
      router.push(`${paragraph.field_link_vacancies.uri.alias}?search=${e}`)
    }
    if (typeof e === 'object') {
      e.preventDefault()
      router.push(
        `${paragraph.field_link_vacancies.uri.alias}?search=${searchInput}`,
      )
    }
  }

  const generateSearchLink = (quickLink: JobSearchLinkTabQuickLink) => {
    const baseLink = quickLink.field_button?.uri.alias
      ? quickLink.field_button?.uri.alias
      : quickLink.field_button?.uri.uri.split('/').slice(3).join('/')
    const lngCode = router.locale?.split('-')[0]
    const additionalQuery = ['']
    const isSEOPageLink =
    quickLink.field_button?.uri.alias &&
    quickLink.field_button?.uri.bundle === 'seo_page'

    if (isSEOPageLink) {
      // SEO Page paths can not be taken from uri 
      const buttonLink = quickLink.field_button ?? null
      const uuid = buttonLink?.uri.uri_uuid.split('/')[2]
      const link = additionalContent.seoPagePaths?.find(
        link => link.id === uuid,
      )
      return link ? link.path : baseLink
    }

    const additionalQueryGenerator = () => {
      if (quickLink.field_domain_matchbox.length > 0) {
        quickLink.field_domain_matchbox.forEach(field_domain =>
          additionalQuery.push(`domainId:${field_domain.domain_id}`),
        )
      }
      if (quickLink.field_location_labels?.length > 0) {
        quickLink.field_location_labels.forEach(field_city => {
          additionalQuery.push(`regionId:${field_city.region_key}`)
          additionalQuery.push(
            `cityLabels.${lngCode === 'en' ? 'nl' : lngCode}:${
              field_city.region_key
            }.${field_city.value}`,
          )
        })
      }
      const arrayWithoutDuplicateValues = [...new Set(additionalQuery)]
      return `${baseLink}${arrayWithoutDuplicateValues.join('&filters=')}`
    }
    return additionalQueryGenerator()
  }

  return (
    <section
      className={`section-box c-job-search-link pb-0 px-4 ${
        paragraph.field_media ? 'with-image' : ''
      }`}
    >
      <div>
        <h1 className="pt-5 pt-lg-0">
          {paragraph.field_title?.split('$vacancies')[0]}{' '}
          <GenericLink
            extraClass="text-yellow"
            link={paragraph.field_link_vacancies}
          >
            {additionalContent.totalAmountVacancies ?? 0}
          </GenericLink>{' '}
          {paragraph.field_title?.split('$vacancies')[1]}
        </h1>
        <form className="c-job-search-link__form p-4 mx-lg-0 position-relative">
          <div className="input-group c-search-input-wrapper position-relative mb-4 mt-lg-0">
            <SearchBox
              searchInput={searchInput}
              extraClass="w-100"
              handleSearchBoxInput={val => setSearchInput(val)}
              suggestions={suggestionsData}
              submitHandler={onSearchSubmit}
            />
            <button
              type="submit"
              className="position-absolute"
              aria-label="Submit search"
              onClick={e => onSearchSubmit(e)}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.96094 2.25C14.0947 2.25 17.4609 5.61621 17.4609 9.75C17.4609 11.5459 16.8311 13.1924 15.7734 14.4844L21.75 20.4609L20.6719 21.5391L14.6953 15.5625C13.4033 16.6201 11.7568 17.25 9.96094 17.25C5.82715 17.25 2.46094 13.8838 2.46094 9.75C2.46094 5.61621 5.82715 2.25 9.96094 2.25ZM9.96094 3.75C6.63867 3.75 3.96094 6.42773 3.96094 9.75C3.96094 13.0723 6.63867 15.75 9.96094 15.75C13.2832 15.75 15.9609 13.0723 15.9609 9.75C15.9609 6.42773 13.2832 3.75 9.96094 3.75Z"
                  fill="#E94E0F"
                />
              </svg>
            </button>
          </div>
          {paragraph.field_tab && paragraph.field_tab.length > 0 ? (
            <>
              <div className="d-none d-lg-block">
                <Tab.Group>
                  <Tab.List className="mb-4">
                    {paragraph.field_tab?.map(tab => (
                      <Tab key={tab.id}>
                        {tab.field_title}
                        <svg
                          className="ml-2"
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M8.32361 4.66675L2.66675 10.3237L3.60956 11.2665L8.32361 6.55247L13.0377 11.2664L13.9805 10.3236L8.32361 4.66675Z" />
                        </svg>
                      </Tab>
                    ))}
                  </Tab.List>
                  <Tab.Panels className="d-none d-lg-block">
                    {paragraph.field_tab?.map(tab => (
                      <Tab.Panel key={tab.id}>
                        {tab.field_quick_link?.map((quickLink, i) => {
                          return (
                            <GenericLink
                              key={quickLink.id}
                              link={generateSearchLink(quickLink)}
                              extraClass={classNames({
                                'no-border':
                                  tab.field_quick_link!.length % 2 === 0
                                    ? i + 1 === tab.field_quick_link!.length ||
                                      i + 1 === tab.field_quick_link!.length - 1
                                    : i + 1 === tab.field_quick_link!.length,
                              })}
                            >
                              {quickLink.field_button?.title}
                            </GenericLink>
                          )
                        })}
                      </Tab.Panel>
                    ))}
                  </Tab.Panels>
                </Tab.Group>
              </div>
              <div className="d-lg-none">
                {paragraph.field_tab?.map((tab, i) => (
                  <Disclosure defaultOpen={i === 0} key={tab.id}>
                    <Disclosure.Button className="w-100 mb-2">
                      {tab.field_title}
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M8.32361 4.66675L2.66675 10.3237L3.60956 11.2665L8.32361 6.55247L13.0377 11.2664L13.9805 10.3236L8.32361 4.66675Z" />
                      </svg>
                    </Disclosure.Button>
                    <Disclosure.Panel className="w-100 mb-2">
                      {tab.field_quick_link?.map((quickLink, i) => (
                        <GenericLink
                          key={quickLink.id}
                          link={quickLink.field_button}
                          extraClass={classNames({
                            'no-border':
                              tab.field_quick_link!.length % 2 === 0
                                ? i + 1 === tab.field_quick_link!.length ||
                                  i + 1 === tab.field_quick_link!.length - 1
                                : i + 1 === tab.field_quick_link!.length,
                          })}
                        >
                          {quickLink.field_button?.title}
                        </GenericLink>
                      ))}
                    </Disclosure.Panel>
                  </Disclosure>
                ))}
              </div>
            </>
          ) : null}
        </form>
      </div>
      <div className="position-relative d-none d-lg-block">
        {paragraph.field_media ? (
          <MediaRenderer
            layout="fill"
            objectFit="cover"
            item={paragraph.field_media}
          />
        ) : null}
      </div>
    </section>
  )
}

export default JobSearchLink
